import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function Dropkick() {
  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontFamily: 'Noto Sans',
          color: 'secondary.main',
          mb: 6,
          mt: 6,
        }}
      >
        Dropkick: Reliable Binary Lifting and Transformation
      </Typography>

      <Typography sx={{ mb: 4 }}>
        Dropkick is a cutting-edge software product designed to lift compiled software binaries into
        an intermediate form, enabling seamless transformation and recompilation. Whether
        you&apos;re modernizing legacy binaries, enhancing security, or implementing novel defenses,
        Dropkick provides the foundation you need to transform complex software systems reliably.
      </Typography>

      <Typography
        variant="h5"
        align="left"
        sx={{
          fontFamily: 'Noto Sans',
          color: 'secondary.main',
          mb: 2,
        }}
      >
        How Dropkick Works
      </Typography>

      <Typography sx={{ mb: 4 }}>Dropkick is built with two powerful components:</Typography>

      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
        The Core
      </Typography>

      <Typography sx={{ mb: 4 }}>
        The heart of Dropkick, capable of lifting Linux (ELF) and Windows (PE) binaries with
        unparalleled precision. Unlike traditional lifting tools that struggle with complex
        binaries, Dropkick’s advanced techniques ensure reliable lifting and recompilation, even for
        the most intricate software. The core is available through a commercial software license.
      </Typography>

      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
        Plugins
      </Typography>

      <Typography sx={{ mb: 4 }}>
        Custom plugins enable you to transform lifted binaries for specific use cases. Plugins can
        be developed by our team to meet your unique needs.
      </Typography>

      <Typography
        variant="h5"
        align="left"
        sx={{
          fontFamily: 'Noto Sans',
          color: 'secondary.main',
          mb: 2,
        }}
      >
        What Can Dropkick Do for You?
      </Typography>

      <Typography sx={{ mb: 2 }}>
        With the Dropkick Core and our specialized plugins, you can:
      </Typography>

      <ul>
        <li>
          <Typography sx={{ mb: 1 }}>
            <strong>Diversify Software Signatures:</strong> Achieve signature diversity to enhance
            software resilience against targeted attacks.
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 1 }}>
            <strong>Modernize Legacy Systems:</strong> Patch and update outdated binaries without
            access to original source code.
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 1 }}>
            <strong>Enhance Security:</strong> Leverage moving target defense strategies to make
            binaries harder to exploit.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="h5"
        align="left"
        sx={{
          fontFamily: 'Noto Sans',
          color: 'secondary.main',
          mb: 2,
        }}
      >
        Why Choose Dropkick?
      </Typography>

      <ul>
        <li>
          <Typography sx={{ mb: 1 }}>
            <strong>Unmatched Reliability:</strong> Dropkick excels where other binary lifting
            technologies fail, handling even the most complex binaries.
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 1 }}>
            <strong>Cross-Platform Support:</strong> Fully supports both ELF (Linux) and PE
            (Windows) binary formats.
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 1 }}>
            <strong>Customizable for Your Needs:</strong> Our team can develop plugins tailored to
            your specific transformation goals.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="h5"
        align="left"
        sx={{
          fontFamily: 'Noto Sans',
          color: 'secondary.main',
          mb: 2,
        }}
      >
        See Dropkick in Action
      </Typography>

      <Typography sx={{ mb: 6 }}>
        Request a demo today and experience how Dropkick can revolutionize the way you work with
        binaries. Email dropkick@r2idef.com.
      </Typography>
    </Container>
  );
}
