import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Layout, NoMatch } from './App';
import Home from './Home';
import Careers from './Careers';
import Dropkick from './Dropkick';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Layout optionalComponent={NoMatch} />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/careers', element: <Careers /> },
      { path: '/commercial', element: <Dropkick /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
